import axios, { AxiosInstance } from "axios";

export const accountClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL,
});

export const subscriptionsClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SUBSCRIPTION_API_URL,
});

export const authClient: AxiosInstance = axios.create({
  baseURL: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const insightClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_INSIGHT,
})

export const harvestClient = axios.create({
  baseURL: process.env.REACT_APP_HARVEST_API_BASE_URL,
  headers: {
      "Content-Type": "application/json",
  },
});