import React, { useEffect } from "react";
import { harvestClient } from "../../services/http";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid #e0e0e0",
    },
    tableContainer: {
      padding: 10,
      paddingRight: 20,
      overflowX: "auto",
    },
    table: {
      border: "1px solid #e0e0e0",
      minWidth: 700,
      padding: 0,
    },
  })
);

type Harvest = {
  id: string;
  harvest_type: string;
  url: string;
  schedule: string;
  sitemap_filter: string | null;
  paths: string[] | null;
  exclude_paths: string[] | null;
  provider: string | "";
  sitemaps: string | null;
  search_terms: string | null;
  qa_mode: boolean;
  bulk_upload_id: string | null;
};

type HarvestsResponse = {
  continuation_key?: string;
  harvests: Harvest[];
};

type HarvestsState = {
  harvests: Harvest[];
  continuationKey: string;
  nextContinuationKey: string;
  continuationKeyStack: string[];
  searchUrl: string;
  loading: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
};

const initialState: HarvestsState = {
  harvests: [],
  continuationKey: "",
  nextContinuationKey: "",
  continuationKeyStack: [],
  searchUrl: "",
  loading: true,
  hasNext: false,
  hasPrevious: false,
};

type HarvestsAction =
  | { type: "INITIAL_DATA"; data: Harvest[]; url: string, nextContinuationKey: string }
  | { type: "SET_LOADING" }
  | { type: "NEXT_PAGE"; data: Harvest[]; nextContinuationKey: string }
  | { type: "PREVIOUS_PAGE"; data: Harvest[]; nextContinuationKey: string }
  | { type: "SET_SEARCH_URL"; url: string };

function harvestsReducer(state: HarvestsState, action: HarvestsAction): HarvestsState {
  switch (action.type) {
    case "INITIAL_DATA":
      const hasNext = action.nextContinuationKey !== "";
      return {
        ...state,
        harvests: action.data,
        continuationKey: "",
        nextContinuationKey: action.nextContinuationKey,
        continuationKeyStack: [],
        searchUrl: action.url,
        loading: false,
        hasNext,
        hasPrevious: false
      };
    case "SET_LOADING":
      return { ...state, loading: true };
    case "NEXT_PAGE":
      const continuationKey = state.nextContinuationKey;
      const continuationKeyStack = [...state.continuationKeyStack, state.continuationKey];
      return {
        ...state,
        harvests: action.data,
        continuationKey,
        nextContinuationKey: action.nextContinuationKey,
        continuationKeyStack,
        loading: false,
        hasNext: action.nextContinuationKey !== "",
        hasPrevious: true
      };
    case "PREVIOUS_PAGE":
      return {
        ...state,
        harvests: action.data,
        continuationKey: state.continuationKeyStack.pop() || "",
        continuationKeyStack: [...state.continuationKeyStack],
        nextContinuationKey: action.nextContinuationKey,
        loading: false,
        hasNext: true,
        hasPrevious: state.continuationKeyStack.length > 0
      };
    case "SET_SEARCH_URL":
      return { ...state, searchUrl: action.url };
    default:
      throw new Error("unkown action type " + action);
  }
}

export default function Harvests() {
  const [harvestsState, harvestsDispatch] = React.useReducer(harvestsReducer, initialState);

  useEffect(() => {
    getFirstHarvests();
    // eslint-disable-next-line
  }, []);

  const getFirstHarvests = async () => {
    try {
      harvestsDispatch({ type: "SET_LOADING" });
      const params = {
        url: harvestsState.searchUrl,
        size: 15
      };
      const response = await harvestClient.get<HarvestsResponse>("/", { params });
      harvestsDispatch({
        type: "INITIAL_DATA",
        data: response.data.harvests,
        url: harvestsState.searchUrl,
        nextContinuationKey: response.data.continuation_key || ""
      });
    } catch (error) {
      console.error("Error fetching harvests:", error);
    }
  }

  const getNextHarvests = async () => {
    try {
      harvestsDispatch({ type: "SET_LOADING" });
      const params = {
        continuation_key: harvestsState.nextContinuationKey,
        size: 15,
        url: harvestsState.searchUrl
      };
      const response = await harvestClient.get<HarvestsResponse>("/", { params });
      harvestsDispatch({
        type: "NEXT_PAGE",
        data: response.data.harvests,
        nextContinuationKey: response.data.continuation_key || ""
      });
    } catch (error) {
      console.error("Error fetching harvests:", error);
    }
  };

  const getPreviousHarvests = async () => {
    try {
      harvestsDispatch({ type: "SET_LOADING" });
      const params = {
        continuation_key: harvestsState.continuationKeyStack[harvestsState.continuationKeyStack.length - 1],
        size: 15,
        url: harvestsState.searchUrl
      };
      const response = await harvestClient.get<HarvestsResponse>("/", { params });
      harvestsDispatch({
        type: "PREVIOUS_PAGE",
        data: response.data.harvests,
        nextContinuationKey: response.data.continuation_key || ""
      });
    } catch (error) {
      console.error("Error fetching harvests:", error);
    }
  };

  const classes = useStyles();

  return (
    <>
      {harvestsState.loading ? (
        <div>Loading...</div>
      ) : (
          <>
            <div>
              <TextField
                variant="outlined"
                value={harvestsState.searchUrl}
                onChange={(e) => harvestsDispatch({ type: "SET_SEARCH_URL", url: e.target.value })}
              />
              <Button onClick={getFirstHarvests}>Search</Button>
            </div>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell>ID</TableCell>
                  <TableCell>Harvest Type</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Schedule</TableCell>
                  <TableCell>Paths</TableCell>
                  <TableCell>Exclude Paths</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Sitemaps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {harvestsState.harvests.map((harvest) => (
                  <TableRow>
                    <TableCell>{harvest.id}</TableCell>
                    <TableCell>{harvest.harvest_type}</TableCell>
                    <TableCell>{harvest.url}</TableCell>
                    <TableCell>{harvest.schedule}</TableCell>
                    <TableCell>{harvest.paths?.join("\n")}</TableCell>
                    <TableCell>{harvest.exclude_paths?.join("\n")}</TableCell>
                    <TableCell>{harvest.provider}</TableCell>
                    <TableCell>{harvest.sitemaps}</TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <IconButton
              onClick={getPreviousHarvests}
                disabled={harvestsState.continuationKeyStack.length === 0}
              aria-label="previous page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={getNextHarvests}
                disabled={harvestsState.nextContinuationKey === "" || harvestsState.harvests.length === 1}
              aria-label="next page"
            >
              <KeyboardArrowRight />
            </IconButton>
          </div>
        </>
      )}
    </>
  );

}

