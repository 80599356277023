import { Auth0Client, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions, RedirectLoginOptions, createAuth0Client } from "@auth0/auth0-spa-js";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import appUrl from "../../appUrl";
import { createConnection } from "../../helpers/analytics";
import { AuthUser, hasPermission } from "../../helpers/authUser";
import { accountClient, insightClient, subscriptionsClient, harvestClient } from "../../services/http";
import { AxiosHeaders } from "axios";

interface Auth0ContextProps {
  isAuthenticated: boolean;
  user: any;
  isSuperAdmin: boolean;
  isDelegatedAdmin: boolean;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: () => void;
  handleRedirectCallback: () => void;
  loginWithRedirect: (opt: RedirectLoginOptions) => void;
  getTokenSilently: (opt: GetTokenSilentlyOptions) => void;
  getTokenWithPopup: (opt: GetTokenWithPopupOptions) => void;
  logout: (opt: LogoutOptions) => void;
}

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext({} as Auth0ContextProps);

export const useAuth0 = (): Auth0ContextProps => useContext(Auth0Context);

interface Auth0ProviderProps {
  domain: string;
  clientId: string;
  redirect_uri: string;
  audience: string;
  onRedirectCallback?: (appState: { targetUrl?: string | undefined }) => void;
  children: JSX.Element;
}

export const Auth0Provider = ({
  audience,
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirect_uri,
  ...initOptions
}: Auth0ProviderProps) => {
  const location = useLocation();
  const history = useHistory();
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthUser>();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isDelegatedAdmin, setIsDelegatedAdmin] = useState(false);
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions,
        authorizationParams: { audience, redirect_uri },
      });
      setAuth0Client(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      let auth0User: AuthUser | undefined;
      if (isAuthenticated) {
        auth0User = await auth0FromHook.getUser<AuthUser>();
        if (auth0User) {
          setIsSuperAdmin(hasPermission(auth0User, "account:superAdmin"));
          setIsDelegatedAdmin(hasPermission(auth0User, "account:delegatedAdmin"));
          try {
            const accessToken = await auth0FromHook.getTokenSilently();
            [accountClient, subscriptionsClient, insightClient, harvestClient].forEach(c => {
              const headers: AxiosHeaders = new AxiosHeaders();
              headers.set('Authorization', `Bearer ${accessToken}`);
              c.defaults.headers = {
                ...c.defaults.headers,
                ...headers.toJSON(),
              };
            });
          } catch (e) {
            auth0FromHook.logout({ logoutParams: { returnTo: appUrl } });
          }

          setUser(auth0User);
          createConnection(auth0User);
          const redirectPath = sessionStorage.getItem('redirect');
          if (redirectPath) {
            sessionStorage.removeItem('redirect');
            history.push(redirectPath);
          }
        }
      } else {
        sessionStorage.setItem('redirect', location.pathname);
      }

      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    }


    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser<AuthUser>();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser<AuthUser>();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isSuperAdmin,
        isDelegatedAdmin,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        loginWithRedirect: (opt) => auth0Client?.loginWithRedirect(opt),
        getTokenSilently: (opt) => auth0Client?.getTokenSilently(opt),
        getTokenWithPopup: (opt) => auth0Client?.getTokenWithPopup(opt),
        logout: (opt) => auth0Client?.logout(opt)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
